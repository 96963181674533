@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
body {
  font-family: "Rubik Variable", "DM Sans", sans-serif;
}

option {
  color: black;
}

/* hide scroll bar globaly */
::-webkit-scrollbar {
  display: none;
}

body {
  scrollbar-width: none;
}

body {
  -ms-overflow-style: none;
}

*:focus {
  outline: none;
}