.input-date-field {
    border-color: white !important;
    border-radius: 25px;
    box-shadow: 0px 18px 40px 0px rgba(112, 144, 176, 0.12);
    font-family: 'DM Sans';

    .react-datepicker__header {
        background-color: white !important;
        border-bottom: 3px solid rgba(0, 0, 0, 0.05);
    }

    .react-datepicker__navigation--next {
        right: 35px;
    }

    .react-datepicker__navigation--previous {
        left: 35px;
    }

    .react-datepicker__navigation-icon::before {
        border-color: rgba(0, 0, 0, 0.8);
    }

    .react-datepicker__day--selected, 
    .react-datepicker__day--selected:hover,
    .react-datepicker__day--keyboard-selected, 
    .react-datepicker__day--keyboard-selected:hover {
        background-color: transparent;
        border-radius: 10px;
    }

    .react-datepicker__day {
        width: 34px;
        height: 34px;
    }

    .react-datepicker__day-names {
        display: flex;
        justify-content: space-around;
    }
}

.input-date-field-grey {
    border-color: white !important;
    border-radius: 25px;
    box-shadow: 0px 18px 40px 0px rgba(112, 144, 176, 0.12);
    font-family: 'DM Sans';

    .react-datepicker__header {
        background-color: white !important;
        border-bottom-color: transparent;
    }

    .react-datepicker__day-names {
        background-color: #0000001A;
        border-radius: 10px;
        margin-top: 20px;
        margin-left: 15px;
        margin-right: 15px;
    }

    .react-datepicker__week {
        margin-left: 10px;
        margin-right: 10px;
    }

    .react-datepicker__navigation--next {
        right: 15px;
        background: #93C3B3;
        width: 34px;
        height: 34px;
        border-radius: 10px;

        .react-datepicker__navigation-icon::before{
            top: 10px;
            left: -5px;
            border-width: 2px 2px 0 0;
        }
    }

    

    .react-datepicker__navigation--previous {
        left: 15px;
        background: #93C3B3;
        width: 34px;
        height: 34px;
        border-radius: 10px;

        .react-datepicker__navigation-icon::before{
            top: 10px;
            right: -3px;
            border-width: 2px 2px 0 0;
        }
    }

    .react-datepicker__navigation-icon::before {
        border-color: white;
    }

    .react-datepicker__day-name{
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -2%;
    }

    .react-datepicker__day--selected, 
    .react-datepicker__day--selected:hover,
    .react-datepicker__day--keyboard-selected, 
    .react-datepicker__day--keyboard-selected:hover {
        background-color: transparent;
        border-radius: 10px;
    }

    .react-datepicker__day {
        width: 34px;
        height: 34px;
    }

    .react-datepicker__day-names {
        display: flex;
        justify-content: space-around;
    }
}

.react-datepicker-popper {
    z-index: 99;
}

.react-datepicker-wrapper {
    width: 100%;
}