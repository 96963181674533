.rbc-calendar {
  min-height: 400px;
}

.rbc-time-view {
  border-radius: 15px;
  /* border: 1px solid #e2e8f0; */
}

.rbc-time-content {
  border-top: 1px solid #e2e8f0;
}

.rbc-row {
  background-color: white;
}
.rbc-row-content {
  background-color: white;
}
.rbc-timeslot-group {
  border-bottom: 1px solid #e2e8f0;
}

.rbc-time-slot {
  min-height: 60px;
  display: flex;
  align-items: center;
  background-color: white;
  border-bottom: 0px solid #e2e8f0;
  border-top: 0px solid #e2e8f0;
}
.rbc-label {
  font-size: 12px;
}
.rbc-time-view {
  border: none;
}
.rbc-events-container {
  margin-right: 0;
}

.rbc-event {
  background-color: #4299e1;
  border-radius: 8px;
  color: white;
  padding: 2px 5px;
}

.rbc-time-header-content {
  border-left: none;
}

.rbc-header {
  padding: 10px;
  font-weight: 600;
}

.rbc-time-content > * + * > * {
  border-left: none;
}

.rbc-time-header.rbc-overflowing {
  border-right: none;
}
