.rbc-month-view {
  border-radius: 8px; /* Adjust the radius as needed */
  flex: 1;
}
.rbc-time-view {
  border-radius: 8px; /* Adjust the radius as needed */
  overflow: hidden;
}

.week-view .rbc-time-header-cell {
  flex-shrink: 0;
}
.week-view .rbc-event {
  width: auto !important; /* works for 2 events in a row */
}
/* only for mobile */

@media (max-width: 768px) {
  .rbc-date-cell {
    text-align: center;
  }
  .week-view .rbc-event {
    max-width: 105% !important;
    margin-left: 0px !important;
  }
  .week-view .rbc-event[style*='left: 1'] {
    left: 5px !important;
  }
  .week-view .rbc-event[style*='left: 25%'] {
    left: 10px !important;
  }
  .week-view .rbc-event[style*='left: 33'] {
    left: 7px !important;
  } 
  .week-view .rbc-event[style*='left: 50'] {
    left: 7px !important;
  }
  .week-view .rbc-event[style*='left: 66'] {
    left: 15px !important;
  }
  .week-view .rbc-event[style*='left: 75'] {
    left: 23px !important;
  }
  .week-view .rbc-event-label {
    width: 100% !important; /* works for 2 events in a row */
    font-size: 11px !important;
  }
  .week-view .rbc-event-content {
    font-size: 11px !important;
  }
}
.rbc-timeslot-group {
  border-width: 0px;
}
.rbc-overlay {
  border-radius: 16px;
  padding: 12px;
}
.rbc-header {
  font-size: 16px;
  font-weight: 700 !important;
}
.rbc-header > .rbc-button-link {
  font-size: 16px;
  font-weight: 700 !important;
}
.rbc-button-link {
  font-size: 16px;
}
.rbc-row {
  margin-bottom: 2px;
}

.rbc-current-time-indicator {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #465d55;
}

.rbc-current-time-indicator::after {
  content: '';
  position: absolute;
  border-radius: 0 2px 2px 0;
  top: 50%;
  left: 100%;
  width: 100px;
  height: 2px;
  background-color: black;
  transform: translateY(-50%);
}

.rbc-event-label {
  font-size: 14px;
  font-weight: 500;
  /* width: 100% !important; */
  /* text-align: center; */
}
.rbc-event {
  align-items: center !important;
}
.rbc-now > button {
  background-color: #aa915d;
  color: white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
}
.rbc-allday-cell {
  background-color: #f7f7f7;
  /* display: none; */
  border: 0px solid #e9e9e9;
}

.rbc-time-content {
  border-top: 0px solid #e9e9e9;
}
.rbc-event-content {
  padding: 0px;
  font-size: 14px;
  font-weight: 500;
  width: fit-content;
}
.rbc-row-content {
  height: 100%;
}

[dir='rtl'] {
  .rbc-event-label {
    padding-left: 5px;
    padding-right: 0;
  }

  .rbc-event-content {
    text-align: right;
  }
}

@keyframes highlight-flash {
  0% {
    background-color: white;
  }
  50% {
    background-color: #93c3b3;
  }
  100% {
    background-color: white;
  }
}

.date-cell-highlight {
  animation: highlight-flash 1s ease;
}

/* added for scrollable events */
.rbc-events-container {
  overflow: auto;
}